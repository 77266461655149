import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DiscoverCarousel from './DiscoverCarousel';
import { useEffect } from 'react';
import { ServiceActions } from '@Actions/ServiceActions';
import moment from 'moment';
import { connect } from 'react-redux';
import i18n from '@i18n';

const Articles = ({ articles, getArticles, locale }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const locale = locale || i18n.language;
    getArticles(locale);
  }, [locale, i18n.language]);

  const getCard = ({ media, title, published, link }) => {
    const {
      thumbnail: { url },
    } = media;
    return (
      <a href={link} target="_blank" rel="noreferrer" key={title}>
        <div className="articles__card" key={title}>
          <img className="articles__image" src={url} />
          <div className="articles__title">{title}</div>
          <div className="articles__date">{moment(published).format('ll')}</div>
        </div>
      </a>
    );
  };

  return (
    <>
      {articles && (
        <div className="articles">
          <div className="articles__header">{t('public.articles.title')}</div>
          <DiscoverCarousel>{articles?.items?.slice(0, 10).map((content) => getCard(content))}</DiscoverCarousel>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  locale: state.auth?.user?.profile?.locale,
  articles: state.service?.articles,
});

const actions = {
  getArticles: ServiceActions.getArticles,
};

export default connect(mapStateToProps, actions)(Articles);
