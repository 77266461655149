import * as React from 'react';
import { Helmet } from 'react-helmet';
import bind from 'bind-decorator';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '@Components/shared/Icon';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { AccountActions } from '@Actions/AccountActions';
import { HashtagActions } from '@Actions/HashtagActions';
import { withTranslation } from 'react-i18next';
import { ReactComponent as CollectionIcon } from '@Images/icons/collection.svg?component';

class Layout extends React.Component {
  scrollbarRef = React.createRef();
  slider;
  isDown = false;
  startX;
  scrollLeft;

  constructor(props) {
    super(props);

    this.state = {
      load: false,
    };
  }

  componentDidMount() {
    this.slider = document.getElementById('type-box');

    this.slider?.addEventListener('mousedown', (e) => {
      this.isDown = true;
      this.startX = e.pageX - this.slider.offsetLeft;
      this.scrollLeft = this.slider.scrollLeft;
    });
    this.slider?.addEventListener('mouseleave', () => {
      this.isDown = false;
    });
    this.slider?.addEventListener('mouseup', () => {
      this.isDown = false;
    });
    this.slider?.addEventListener('mousemove', (e) => {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.slider.offsetLeft;
      const walk = (x - this.startX) * 3; //scroll-fast
      this.slider.scrollLeft = this.scrollLeft - walk;
    });
  }

  componentWillUnmount() {
    /* This prevents "Can't perform a React state update on an unmounted... 
            but it indicates a memory leak" error. with returning null, 
            it will no longer hold any data in the memory.
            https://stackoverflow.com/a/61055910 */
    this.setState = (state, callback) => {
      return;
    };
  }

  @bind
  onScroll(e) {
    let max = this.scrollbarRef.current.osInstance().scroll().max.y;
    let current = this.scrollbarRef.current.osInstance().scroll().position.y;
    if (max - current < max / 10) {
      this.setState({ load: true });
    } else {
      this.setState({ load: false });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="scrollbar-div">
        <div id="page-discovere">
          <Helmet>
            <title></title>
          </Helmet>
          <div className="list" id="type-box">
            <NavLink
              exact
              isActive={(match, location) => {
                if (location.pathname === '/' || location.pathname === '/discover') {
                  return true;
                }
                return false;
              }}
              activeClassName="active"
              className="mr-3"
              to="/discover"
              onClick={() => {}}>
              <Icon className="mr-1" src="fas fa-compass" type="fontawesome" />
              {t('public.overview')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/creations" onClick={() => {}}>
              <Icon className="mr-1" src="fas fa-icons" type="fontawesome" />
              {t('public.creations')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/on-sale" onClick={() => {}}>
            <Icon className="mr-1" src="fas fa-circle-notch" type="fontawesome" />
              {t('public.onSale')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/live-auctions" onClick={() => {}}>
              <Icon className="mr-1" src="fas fa-clock" type="fontawesome" />
              {t('public.liveAuctions')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/collections" onClick={() => {}}>
              <CollectionIcon className="mr-1" width={16} height={16} />
              {t('public.collections')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/creators" onClick={() => {}}>
              <Icon className="mr-1" src="fas fa-feather-alt" type="fontawesome" />
              {t('public.creators')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/hashtags" onClick={() => {}}>
              <Icon className="mr-1" src="fas fa-hashtag" type="fontawesome" />
              {t('public.hashtags')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/popular" onClick={() => {}}>
              {t('public.mostPopular')}
            </NavLink>
            <NavLink activeClassName="active" className="mr-3" to="/discover/trending" onClick={() => {}}>
              {t('public.trendingNow')}
            </NavLink>
          </div>
          {React.cloneElement(this.props.children, { load: this.state.load })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...CollectibleActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.search,
  ...state.service,
  ...state.user.account,
  ...state.discover,
  ...state.cache,
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation(null, { withRef: true })(Layout),
);
