import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '@Components/shared/Icon';
import AppVariables from '@AppVariables';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { CollectionActions } from '@Actions/CollectionActions';
import { AccountActions } from '@Actions/AccountActions';
import Carousel from '@Components/shared/Carousel';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import { AnimatedPlaceholderRect, CollectibleCardPlaceholder } from '@Components/placeholder/PlaceholderComponents';
import { InlineLoader } from '@Components/shared/Spinner';
import AppScroller from '@Components/shared/AppScroller';
import { HashtagActions } from '@Actions/HashtagActions';
import { createSelector } from 'reselect';
import LazyRender from '@Components/shared/LazyRender';
import { withTranslation } from 'react-i18next';
import CollectionCard from '@Components/shared/CollectionCard';
import { runtimeConfig } from '../../config';

const collectionFilterQuery = runtimeConfig.enableDisplayTestNetworks
  ? '$filter=(account/id ne null) and collectiblesCount gt 0'
  : '$filter=(account/id ne null and chain/testnetwork eq false) and collectiblesCount gt 0';

class Creations extends React.Component {
  LIMIT = 32;

  constructor(props) {
    super(props);
    this.state = {
      responsiveButton: false,
      shouldLoadMore: true,
      popular: true,
      trending: true,
      recent: true,
    };
  }

  entries = [
    {
      id: 1,
      groupName: this.props.t('public.trendingNow'),
      description: this.props.t('public.trendingNowCollectionDesc'),
      query: `${collectionFilterQuery}&$top=${this.LIMIT}`,
      group: 'trending',
    },
    {
      id: 2,
      groupName: this.props.t('public.recentCreated'),
      description: this.props.t('public.recentCreatedCollectionsDesc'),
      query: `${collectionFilterQuery}&$top=${this.LIMIT}`,
      group: 'recent',
    },
    {
      id: 3,
      groupName: this.props.t('public.mostPopular'),
      description: this.props.t('public.mostPopulerCollectionDesc'),
      query: `${collectionFilterQuery}&$top=${this.LIMIT}`,
      group: 'popular',
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.group !== this.props.match.params.group ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.setState({ shouldLoadMore: true }, () => {
        this.createRequest();
      });
    }
  }

  componentDidMount() {
    this.createRequest();
  }

  createRequest(loadMore = false) {
    let params = new URLSearchParams(this.props.location.search);
    let selectedCategory = this.props.selectedCollectibleCategories?.find((x) => x.Name === params.get('category'));
    let selectedCategoryName = selectedCategory ? selectedCategory.Name : 'All Content';

    const group = this.props.match.params.group || '';
    this.entries
      .filter((e) => (group ? e.group === group : true))
      .forEach((entry) => {
        let { query, group } = entry;
        const length = this.props.collections[group][selectedCategoryName]?.length || 0;
        if (!length || loadMore) {
          if (selectedCategory && selectedCategory.Id !== 0) {
            query += `&$filter=categoryId eq ${selectedCategory.Id}`;
          }
          query += `&$skip=${length}`;
          this.props.getCollections(query, group, selectedCategoryName).then((res) => {
            this.setState({
              [group]: res.payload.value.length >= this.LIMIT,
            });
          });
        }
      });
  }

  updateHistory(id) {
    const entry = this.entries.find((x) => x.id == id);

    if (entry) {
      this.props.history.push(`/discover/collections/${entry.group}`);
    }
  }

  loadMore() {
    this.createRequest(true);
  }

  render() {
    const { t } = this.props;
    let params = new URLSearchParams(this.props.location.search);
    const group = this.props.match.params.group || '';
    let selectedCategory = this.props.selectedCollectibleCategories?.find((x) => x.Name === params.get('category'));
    let selectedCategoryName = selectedCategory ? selectedCategory.Name : 'All Content';

    if (group && !this.entries.some((e) => e.group === group)) {
      return <Redirect to="/discover/collections" />;
    }

    return (
      <div className="tab-container">
        <Helmet>
          <title> {t('public.collections')} </title>
        </Helmet>
        <div className="header-text ml-3">
          <h1 className="my-1" data-test-id="collections-top-header">{selectedCategory?.Name || t('public.collections')}</h1>
          <div className="text-grey">
            {selectedCategory
              ? t('public.discoverTextWithCategory', { name: selectedCategory?.Name.toLowerCase() })
              : t('public.discoverCollectionsText')}
          </div>
        </div>
        {
          <div className="carousel-container">
            <Carousel
              isCarousel
              collections={this.props.collections}
              group="trending"
              page="Collections"
              selectedCategoryName={selectedCategoryName}
            />
          </div>
        }
        <div className="top-bar" style={!group ? { display: 'none' } : null}>
          {group ? (
            <div className="selectable-item">
              <div className={this.state.responsiveButton ? 'select-input active-select-input' : 'select-input'}>
                <div className="home-button" onClick={() => this.props.history.goBack()}>
                  <Icon className="mr-1" type="fontawesome" src="fas fa-arrow-left" />
                  {t('public.back')}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <>
          {this.entries
            .filter((e) => (group ? e.group === group : true))
            .map((entry) => (
              <AppScroller
                key={entry.id}
                dataLength={this.props.collections[entry.group][selectedCategoryName]?.length || 0}
                next={() => this.loadMore()}
                hasMore={group ? this.state[entry.group] : false}
                loader={<InlineLoader height={30} />}>
                {
                  <DiscoverCardGroup
                    key={'discover-group-' + entry.id}
                    className="mt-3"
                    hideCarousel={group}
                    hideInfo={!!group}
                    seeAll={() => this.updateHistory(entry.id)}
                    subtitle={entry.description}
                    title={entry.groupName}>
                    {this.props.collections[entry.group][selectedCategoryName]?.length
                      ? this.props.collections[entry.group][selectedCategoryName].map((collectionId) => {
                          const collection = this.props.allCollections[collectionId];
                          return (
                            collection && (
                              <LazyRender key={'lazy-' + collection.id} Placeholder={CollectibleCardPlaceholder}>
                                <CollectionCard key={'collection-' + collection.id} collection={collection} />
                              </LazyRender>
                            )
                          );
                        })
                      : new Array(this.LIMIT).fill(0).map((i) => (
                          <div key={'account' + i} className="w-100 placeholder-card">
                            <div style={{ position: 'relative' }}>
                              <div style={{ display: 'block', paddingBottom: '100%' }} />
                              <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                            </div>
                          </div>
                        ))}
                  </DiscoverCardGroup>
                }
              </AppScroller>
            ))}
        </>
      </div>
    );
  }
}

const getCollectibleCategories = () => AppVariables.collectibleCategories;

export const selectCollectibleCategories = createSelector(getCollectibleCategories, (categories) => {
  const collectibleCategoryFilterItem = [{ Id: 0, Name: 'All Content' }];

  if (categories) {
    return [...collectibleCategoryFilterItem, ...categories];
  }

  return collectibleCategoryFilterItem;
});

const mapDispatchToProps = {
  ...AccountActions,
  ...SpinnerActions,
  ...CollectionActions,
  ...ServiceActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.service,
  ...state.user.account,
  ...state.discover,
  ...state.cache,
  selectedCollectibleCategories: selectCollectibleCategories(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation()(Creations));
