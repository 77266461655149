import React, { useRef } from 'react';
import { Player } from 'video-react';
import Icon from '@Components/shared/Icon';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const VIDEO_SOURCE = 'https://player.vimeo.com/video/710614950?h=c3338a67ec&title=0&byline=0&portrait=0&transparent=0';

const MeetOix = () => {
  const { t } = useTranslation();

  return (
    <div className="meetOix" id="meetOix">
      <div className="meetOix__title">{t('public.meetOix.title')}</div>
      <div className="meetOix__subTitle">{t('public.meetOix.subTitle')}</div>
      <div className="meetOix__videoContainer">
        <iframe
          className='meetOix__videoFrame'
          src={VIDEO_SOURCE}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default MeetOix;
