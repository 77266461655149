import * as React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ModalActions } from '../actions/ModalActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { AccountActions } from '@Actions/AccountActions';
import AppRoute from '@Components/shared/AppRoute';
import { HashtagActions } from '@Actions/HashtagActions';
import Hashtags from '@Pages/Discover/Hashtags';
import Creations from '@Pages/Discover/Creations';
import LiveAuctions from '@Pages/Discover/LiveAuctions';
import Creators from '@Pages/Discover/Creators';
import Collections from '@Pages/Discover/Collections';
import Templates from '@Pages/Discover/Templates';
import Overview from '@Pages/Discover/Overview';
import DiscoverLayout from '@Pages/Discover/Layout';
import OnSale from './Discover/OnSale';

class DiscoverPage extends React.Component {
  componentDidMount() {
    const pathname = window.location.pathname;
    if (pathname === '/') {
      window.history.pushState({}, '', '/discover');
    }

    window.scrollTo(0, 0);

    this.unlisten = this.props.history.listen(() => {
      window.scrollTo(0, 0);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <>
        <Switch>
          <AppRoute
            exact
            component={Overview}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover', '/']}
          />
          <AppRoute
            exact
            component={Creators}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover/creators/:group', '/discover/creators']}
          />
          <AppRoute
            exact
            component={Creations}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover/creations/:group', '/discover/creations']}
          />
          <AppRoute
            exact
            component={LiveAuctions}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover/live-auctions/:group', '/discover/live-auctions']}
          />
          <AppRoute
            exact
            component={OnSale}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover/on-sale/:group', '/discover/on-sale']}
          />
          <AppRoute
            exact
            component={Collections}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover/collections/:group', '/discover/collections']}
          />
          <AppRoute
            exact
            component={Hashtags}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover/hashtags/:group', '/discover/hashtags']}
          />
          <AppRoute
            exact
            component={Templates}
            layout={DiscoverLayout}
            layoutProps={this.props}
            path={['/discover/popular', '/discover/trending']}
          />
          <Redirect to="/discover" />
        </Switch>
      </>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...ModalActions,
  ...SpinnerActions,
  ...CollectibleActions,
  ...ServiceActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.search,
  ...state.service,
  ...state.user.account,
  ...state.discover,
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(DiscoverPage));
