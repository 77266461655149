import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Feature1 from '@Images/features/feature_001@2x.jpg';
import Feature2 from '@Images/features/feature_002@2x.jpg';
import Feature3 from '@Images/features/feature_003@2x.jpg';
import Feature4 from '@Images/features/feature_004@2x.jpg';
import Button from '@Components/form/Button';
import { Link } from 'react-router-dom';

const ProductFeatures = () => {
  const { t } = useTranslation();

  const productFeaturesContent = [
    {
      image: Feature1,
      title: 'public.featuredProducts.social.title',
      subTitle: 'public.featuredProducts.social.subTitle',
      description: 'public.featuredProducts.social.description',
      primaryButton: {
        text: 'public.featuredProducts.social.primaryText',
        link: '/discover/creations',
      },
      secondaryButton: {
        text: 'public.featuredProducts.social.secondaryText',
        link: 'https://blog.oix.app/introducing-oix/',
      },
    },
    {
      image: Feature2,
      title: 'public.featuredProducts.community.title',
      subTitle: 'public.featuredProducts.community.subTitle',
      description: 'public.featuredProducts.community.description',
      primaryButton: {
        text: 'public.featuredProducts.community.primaryText',
        link: '/discover/creators',
      },
      secondaryButton: {
        text: 'public.featuredProducts.community.secondaryText',
        link: 'https://blog.oix.app/',
      },
    },
    {
      image: Feature3,
      title: 'public.featuredProducts.multiChain.title',
      subTitle: 'public.featuredProducts.multiChain.subTitle',
      description: 'public.featuredProducts.multiChain.description',
      primaryButton: {
        text: 'public.featuredProducts.multiChain.primaryText',
        link: '/discover/on-sale',
      },
      secondaryButton: {
        text: 'public.featuredProducts.multiChain.secondaryText',
        link: 'https://blog.oix.app/utility-nfts-the-future-of-digital-assets/',
      },
    },
    {
      image: Feature4,
      title: 'public.featuredProducts.discover.title',
      subTitle: 'public.featuredProducts.discover.subTitle',
      description: 'public.featuredProducts.discover.description',
      primaryButton: {
        text: 'public.featuredProducts.discover.primaryText',
        link: '/discover/live-auctions',
      },
      secondaryButton: {
        text: 'public.featuredProducts.discover.secondaryText',
        link: 'https://blog.oix.app/an-insiders-guide-to-the-metaverse/',
      },
    },
  ];

  const getCard = ({ icon, title, subTitle, image, description, primaryButton, secondaryButton }) => {
    return (
      <div className="productFeatures__card" key={title}>
        <div className="productFeatures__info">
          <div className="productFeatures__icon">{icon}</div>
          <div className="productFeatures__title">
            <Trans i18nKey={title}>
              0<span className="featured">1</span>2
            </Trans>
          </div>
          <div className="productFeatures__description">{t(description)}</div>
          <div className="productFeatures__buttons">
            <Link to={primaryButton.link}>
              <Button text={t(primaryButton.text)} type="primary" />
            </Link>
            <a href={secondaryButton.link} target="_blank" rel="noopener noreferrer">
              <Button text={t(secondaryButton.text)} type="white" />
            </a>
          </div>
          <div className="productFeatures__more">
            <a href={secondaryButton.link} target="_blank" rel="noopener noreferrer">
              {t(secondaryButton.text)}
            </a>
          </div>
        </div>
        <img className="productFeatures__image" src={image} />
      </div>
    );
  };

  return <div className="productFeatures">{productFeaturesContent.map((content) => getCard(content))}</div>;
};

export default ProductFeatures;
