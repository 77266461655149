import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { AccountActions } from '@Actions/AccountActions';
import { HashtagActions } from '@Actions/HashtagActions';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import {
  AnimatedPlaceholderRect,
  AccountCardPlaceholder,
  CollectibleCardPlaceholder,
} from '@Components/placeholder/PlaceholderComponents';
import AccountCard from '@Components/shared/AccountCard';
import HashtagCard from '@Components/shared/HashtagCard';
import CollectibleCard from '@Components/shared/CollectibleCard';
import LazyRender from '@Components/shared/LazyRender';
import { withTranslation } from 'react-i18next';
import HowToStart from '@Components/discover/HowToStart';
import ProductFeatures from '@Components/discover/ProductFeatures';
import MeetOix from '@Components/discover/MeetOix';
import MainSlider from '@Components/discover/MainSlider';
import Articles from '@Components/discover/Articles';
import AppVariables from '@AppVariables';
import Icon from '@Components/shared/Icon';
import CollectionCard from '@Components/shared/CollectionCard';
import { CollectionActions } from '@Actions/CollectionActions';
import FeaturedCollections from '@Components/discover/FeaturedCollections';
import { runtimeConfig } from 'config';

class Overview extends React.Component {
  LIMIT = 32;
  constructor(props) {
    super(props);
  }

  features = {
    title: 'public.mostPopular',
    description: 'public.templateMostPopularDesc',
    type: 'trending',
    groups: [
      {
        id: 4,
        groupName: 'public.collections',
        description: 'public.trendingNowCollectionDesc',
        url: 'collections',
        group: 'collections',
        category: 'All Content',
        fetchData: () => this.props.getCollections(`$top=${this.LIMIT}`, 'trending', 'All Content'),
      },

      {
        id: 2,
        groupName: 'public.onSale',
        description: 'public.trendingNowOnSaleNftDesc',
        url: 'on-sale',
        group: 'onSale',
        category: 'All Content',
        fetchData: () => this.props.getOnSaleItems(`$top=${this.LIMIT}`, 'trending', 'All Content'),
      },
      {
        id: 3,
        groupName: 'public.liveAuctions',
        description: 'public.trendingNowAuctionDesc',
        url: 'live-auctions',
        group: 'liveAuctions',
        category: 'All Content',
        fetchData: () => this.props.getLiveAuctionItems(`$top=${this.LIMIT}`, 'trending', 'All Content'),
      },
      {
        id: 1,
        groupName: 'public.creations',
        description: 'public.trendingNowCreationDesc',
        url: 'creations',
        group: 'creations',
        category: 'All Content',
        fetchData: () => this.props.getCollectibles(`$top=${this.LIMIT}`, 'trending', 'All Content'),
      },
      {
        id: 5,
        groupName: 'public.creators',
        description: 'public.trendingNowCreatorDesc',
        url: 'creators',
        group: 'creators',
        category: 'All Content',
        fetchData: () => this.props.getAccounts(`$expand=Profile&$top=${this.LIMIT}`, 'trending', 'All Content'),
      },
    ],
  };

  componentDidMount() {
    this.createRequest();
  }

  createRequest() {
    this.features.groups.forEach((grp) => {
      const { group, category, fetchData } = grp;
      const length = this.props[group][this.features.type][category]?.length || 0;
      if (!length) {
        fetchData();
      }
    });
  }

  updateCategory(category, group) {
    this.props.history.push(`/discover/creations/${group}?category=${category}`);
  }

  categoryCard(category) {
    const { Id, Img, IconSrc, Name } = category;
    return (
      <Link
        key={'category-card-id' + Id}
        className="feature-card"
        to={`/discover/creations/trending?category=${Name}`}>
        <div className="height-decider"></div>
        <div className="feature-container">
          <img className="feature-image" src={Img} />
          <div className="feature-icon">
            <Icon src={IconSrc} type="fontawesome" />
          </div>
          <div className="feature-text">{Name}</div>
        </div>
      </Link>
    );
  }

  getCard(group, id) {
    switch (group) {
      case 'creators': {
        const account = this.props.allAccounts[id];
        return account && <AccountCard key={`creators-${id}`} accountData={account} />;
      }
      case 'hashtags': {
        const hashtag = this.props.allHashtags[id];
        return (
          hashtag && (
            <HashtagCard
              key={'hashtag' + hashtag.tag + id}
              followHashtag={this.props.followHashtag}
              getHashtag={this.props.getHashtag}
              hashtag={hashtag}
              history={this.props.history}
              unfollowHashtag={this.props.unfollowHashtag}
            />
          )
        );
      }
      case 'onSale':
      case 'creations':
      case 'liveAuctions': {
        const collectible = this.props.allCollectibles[id];
        return collectible && <CollectibleCard key={'collectible-' + id} collectible={collectible} />;
      }
      case 'collections': {
        const collection = this.props.allCollections[id];
        return collection && <CollectionCard key={'collection-' + id} collection={collection} />;
      }
    }
  }

  seeAll(group, type) {
    this.props.history.push(`/discover/${group}/${type}`);
  }

  updateHistory(group, type) {
    this.props.history.push(`/discover/${group}/${type}`);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="tab-container">
        <Helmet>
          <title> {t('public.discover')} </title>
        </Helmet>
        <MainSlider />
        { runtimeConfig.enableFeaturedCollections && <FeaturedCollections /> }
        {this.features.groups.map((grp) => {
          const { url, group, category } = grp;
          const ids = this.props[group][this.features.type][category] || this.props[group][this.features.type];

          return (
            <DiscoverCardGroup
              key={'discover-group-' + grp.id}
              className="mt-3"
              seeAll={() => this.seeAll(url, this.features.type)}
              subtitle={this.props.t(grp.description)}
              title={this.props.t(grp.groupName)}>
              {ids.length
                ? ids.map((id) => {
                    return (
                      <LazyRender
                        key={'lazy-' + id}
                        Placeholder={group == 'creators' ? AccountCardPlaceholder : CollectibleCardPlaceholder}>
                        {this.getCard(group, id)}
                      </LazyRender>
                    );
                  })
                : new Array(this.LIMIT).fill(0).map((i) => (
                    <div key={'account' + i} className="w-100 placeholder-card">
                      <div style={{ position: 'relative' }}>
                        <div style={{ display: 'block', paddingBottom: '100%' }} />
                        <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                      </div>
                    </div>
                  ))}
            </DiscoverCardGroup>
          );
        })}
        <DiscoverCardGroup
          title={this.props.t('public.categories')}
          subtitle={this.props.t('public.popularCategories')}
          key={'discover-group-c'}
          className="mb-5">
          {AppVariables.collectibleCategories.map((category) => {
            return this.categoryCard(category, 'Most Popular');
          })}
        </DiscoverCardGroup>
        <ProductFeatures />
        <HowToStart />
        <Articles />
        <MeetOix />
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...CollectibleActions,
  ...HashtagActions,
  ...CollectionActions,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.search,
  ...state.service,
  ...state.user.account,
  ...state.discover,
  ...state.cache,
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation()(Overview));
