import React, { createRef, useLayoutEffect, useMemo, useState } from 'react';

const LazyRender = ({ children, threshold, rootMargin, onVisible, Placeholder }) => {
  const ref = useMemo(() => createRef(), []);

  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const options = {
      rootMargin: rootMargin ?? '0px',
      threshold: threshold ?? 0,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();

          if (onVisible) {
            onVisible();
          }
        }
      });
    }, options);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [threshold, rootMargin, ref, onVisible]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={ref}>
      {isVisible ? children : Placeholder ? <Placeholder /> : null}
    </div>
  );
};

export default LazyRender;
