import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { CollectionActions } from '@Actions/CollectionActions';
import { AccountActions } from '@Actions/AccountActions';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import AccountCard from '@Components/shared/AccountCard';
import {
  AnimatedPlaceholderRect,
  CollectibleCardPlaceholder,
  AccountCardPlaceholder,
} from '@Components/placeholder/PlaceholderComponents';
import CollectibleCard from '@Components/shared/CollectibleCard';
import { HashtagActions } from '@Actions/HashtagActions';
import HashtagCard from '@Components/shared/HashtagCard';
import moment from 'moment';
import LazyRender from '@Components/shared/LazyRender';
import { withTranslation } from 'react-i18next';
import CollectionCard from '@Components/shared/CollectionCard';

class Templates extends React.Component {
  LIMIT = 32;

  constructor(props) {
    super(props);
  }

  features = {
    popular: {
      title: this.props.t('public.mostPopular'),
      description: this.props.t('public.templateMostPopularDesc'),
      type: 'popular',
      groups: [
        {
          id: 1,
          groupName: this.props.t('public.creations'),
          description: this.props.t('public.mostPopulerCreationDesc'),
          url: 'creations',
          group: 'creations',
          category: 'All Content',
          fetchData: () => this.props.getCollectibles(`$top=${this.LIMIT}`, 'popular', 'All Content'),
        },
        {
          id: 2,
          groupName: this.props.t('public.onSale'),
          description: this.props.t('public.mostPopularOnSaleNftDesc'),
          url: 'on-sale',
          group: 'onSale',
          category: 'All Content',
          fetchData: () =>
            this.props.getOnSaleItems(
              `$top=${this.LIMIT}`,
              'popular',
              'All Content',
            ),
        },
        {
          id: 3,
          groupName: this.props.t('public.liveAuctions'),
          description: this.props.t('public.mostPopulerAuctionDesc'),
          url: 'live-auctions',
          group: 'liveAuctions',
          category: 'All Content',
          fetchData: () =>
            this.props.getLiveAuctionItems(
              `$top=${this.LIMIT}`,
              'popular',
              'All Content',
            ),
        },
        {
          id: 4,
          groupName: this.props.t('public.collections'),
          description: this.props.t('public.mostPopulerCollectionDesc'),
          url: 'collections',
          group: 'collections',
          category: 'All Content',
          fetchData: () =>
            this.props.getCollections(
              `$top=${this.LIMIT}`,
              'popular',
              'All Content',
            ),
        },
        {
          id: 5,
          groupName: this.props.t('public.creators'),
          description: this.props.t('public.mostPopulerCreatorDesc'),
          url: 'creators',
          group: 'creators',
          category: 'All Content',
          fetchData: () => this.props.getAccounts(`$expand=Profile&$top=${this.LIMIT}`, 'popular', 'All Content'),
        },
        {
          id: 6,
          groupName: this.props.t('public.hashtags'),
          description: this.props.t('public.hastagsSubtext'),
          url: 'hashtags',
          group: 'hashtags',
          category: 'All Content',
          fetchData: () => this.props.getHashtags(`$top=${this.LIMIT}`, 'popular'),
        },
      ],
    },
    trending: {
      title: this.props.t('public.trendingNow'),
      description: this.props.t('public.templateTrendingDesc'),
      type: 'trending',
      groups: [
        {
          id: 1,
          groupName: this.props.t('public.creations'),
          description: this.props.t('public.trendingNowCreationDesc'),
          url: 'creations',
          group: 'creations',
          category: 'All Content',
          fetchData: () => this.props.getCollectibles(`$top=${this.LIMIT}`, 'trending', 'All Content'),
        },
        {
          id: 2,
          groupName: this.props.t('public.onSale'),
          description: this.props.t('public.mostPopularOnSaleNftDesc'),
          url: 'on-sale',
          group: 'onSale',
          category: 'All Content',
          fetchData: () =>
            this.props.getOnSaleItems(
              `$top=${this.LIMIT}`,
              'trending',
              'All Content',
            ),
        },
        {
          id: 3,
          groupName: this.props.t('public.liveAuctions'),
          description: this.props.t('public.trendingNowAuctionDesc'),
          url: 'live-auctions',
          group: 'liveAuctions',
          category: 'All Content',
          fetchData: () =>
            this.props.getLiveAuctionItems(
              `$top=${this.LIMIT}`,
              'trending',
              'All Content',
            ),
        },
        {
          id: 4,
          groupName: this.props.t('public.collections'),
          description: this.props.t('public.trendingNowCollectionDesc'),
          url: 'collections',
          group: 'collections',
          category: 'All Content',
          fetchData: () =>
            this.props.getCollections(
              `$top=${this.LIMIT}`,
              'trending',
              'All Content',
            ),
        },
        {
          id: 5,
          groupName: this.props.t('public.creators'),
          description: this.props.t('public.trendingNowCreatorDesc'),
          url: 'creators',
          group: 'creators',
          category: 'All Content',
          fetchData: () => this.props.getAccounts(`$expand=Profile&$top=${this.LIMIT}`, 'trending', 'All Content'),
        },
        {
          id: 6,          
          groupName: this.props.t('public.hashtags'),
          description: this.props.t('public.hastagsTrendSubtext'),
          url: 'hashtags',
          group: 'hashtags',
          category: 'All Content',
          fetchData: () => this.props.getHashtags(`$top=${this.LIMIT}`, 'trending'),
        },
      ],
    },
  };

  componentDidMount() {
    this.createRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      this.createRequest();
    }
  }

  createRequest() {
    const pageTab = this.features[this.getPage()];
    const { type } = pageTab;

    pageTab.groups.forEach((grp) => {
      const { group, category, fetchData } = grp;

      const length = this.props[group][type][category]?.length || this.props[group][type]?.length || 0;
      if (!length) {
        fetchData();
      }
    });
  }

  getPage() {
    const { history } = this.props;
    return history.location.pathname.split('/')[2];
  }

  seeAll(group, type) {
    this.props.history.push(`/discover/${group}/${type}`);
  }

  getCard(group, id) {
    switch (group) {
      case 'creators': {
        const account = this.props.allAccounts[id];
        return account && <AccountCard key={`creators-${id}`} accountData={account} />;
      }
      case 'hashtags': {
        const hashtag = this.props.allHashtags[id];
        return (
          hashtag && (
            <HashtagCard
              key={'hashtag' + hashtag.tag + id}
              followHashtag={this.props.followHashtag}
              getHashtag={this.props.getHashtag}
              hashtag={hashtag}
              history={this.props.history}
              unfollowHashtag={this.props.unfollowHashtag}
            />
          )
        );
      }
      case 'onSale':
      case 'creations':
      case 'liveAuctions': {
        const collectible = this.props.allCollectibles[id];
        return collectible && <CollectibleCard key={'collectible-' + id} collectible={collectible} />;
      }
      case 'collections': {
        const collection = this.props.allCollections[id];
        return collection && <CollectionCard key={'collection-' + id} collection={collection} />;
      }
    }
  }

  render() {
    const pageTab = this.features[this.getPage()];
    const { type, groups } = pageTab;

    return (
      <div className="template tab-container">
        <Helmet>
          <title>{pageTab.title}</title>
        </Helmet>
        <div className="header-text ml-3">
          <h1 className="my-1">{pageTab.title}</h1>
          <div className="text-grey">{pageTab.description}</div>
        </div>
        <div>
          {groups.map((grp) => {
            const { url, group, category } = grp;
            const ids = this.props[group][type][category] || this.props[group][type];

            return (
              <DiscoverCardGroup
                key={'discover-group-' + grp.id + type}
                className="mt-3"
                seeAll={() => this.seeAll(url, type)}
                subtitle={grp.description}
                title={grp.groupName}>
                {ids.length
                  ? ids.map((id) => {
                      return (
                        <LazyRender
                          key={'lazy-' + id}
                          Placeholder={group == 'creators' ? AccountCardPlaceholder : CollectibleCardPlaceholder}>
                          {this.getCard(group, id)}
                        </LazyRender>
                      );
                    })
                  : new Array(this.LIMIT).fill(0).map((i) => (
                      <div key={'account' + i} className="w-100 placeholder-card">
                        <div style={{ position: 'relative' }}>
                          <div style={{ display: 'block', paddingBottom: '100%' }} />
                          <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                        </div>
                      </div>
                    ))}
              </DiscoverCardGroup>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...CollectibleActions,
  ...HashtagActions,
  ...CollectionActions,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.user.account,
  ...state.discover,
  ...state.cache,
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation()(Templates));
