import React from 'react';
import bind from 'bind-decorator';
import { withRouter } from 'react-router-dom';

import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { AccountActions } from '@Actions/AccountActions';
import { connect } from 'react-redux';
import { AnimatedPlaceholderRect, CollectibleCardPlaceholder } from '@Components/placeholder/PlaceholderComponents';
import CollectibleCard from './CollectibleCard';
import LazyRender from '@Components/shared/LazyRender';
import CollectionCardDetailed from '@Components/shared/CollectionCardDetailed';

class Carousel extends React.Component {
  timer;
  interval = 5000;

  constructor(props) {
    super(props);

    this.state = {
      activeImageIndex: 0,
      itemList: [],
    };
  }

  componentDidMount() {
    //this.getImageInfo();
    this.displayImage(0);
  }

  componentWillUnmount() {
    /* This prevents "Can't perform a React state update on an unmounted... 
            but it indicates a memory leak" error. with returning null, 
            it will no longer hold any data in the memory.
            https://stackoverflow.com/a/61055910 */
    this.setState = (state, callback) => {
      return;
    };
  }

  @bind
  displayImage(imageIndex) {
    if (this.timer) clearTimeout(this.timer);

    this.setState({ activeImageIndex: imageIndex }, () => {
      this.timer = setTimeout(this.displayNextImage, this.interval);
    });
  }

  @bind
  displayNextImage() {
    this.displayImage((this.state.activeImageIndex + 1) % this.state.itemList.length);
  }

  render() {
    const { selectedCategoryName, group } = this.props;
    return (
      <div className="carousel" style={{ height: this.props.height }}>
        {this.props.collections ? (
          <DiscoverCardGroup key={'discover-group'} carousel hideInfo isCarousel className="mt-3">
            {this.props.collections[group || 'trending'][selectedCategoryName]?.length
              ? this.props.collections[group || 'trending'][selectedCategoryName].map((id) => {
                  const collection = this.props.allCollections[id];
                  return (
                    collection && (
                      <LazyRender key={'collection-' + collection.id} Placeholder={CollectibleCardPlaceholder}>
                        <CollectionCardDetailed key={'collection-' + collection.id} collection={collection} />
                      </LazyRender>
                    )
                  );
                })
              : new Array(20).fill(0).map((i) => (
                  <div key={'card' + i} className="w-100 h-100 placeholder-card" style={{ marginBottom: '30px' }}>
                    <div style={{ position: 'relative' }}>
                      <div className="height-decider" style={{ display: 'block', paddingBottom: '100%' }} />
                      <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                    </div>
                  </div>
                ))}
          </DiscoverCardGroup>
        ) : (
          <DiscoverCardGroup key={'discover-group'} carousel hideInfo isCarousel className="mt-3">
            {this.props.collectibles[group || 'trending'][selectedCategoryName]?.length
              ? this.props.collectibles[group || 'trending'][selectedCategoryName].map((id) => {
                  const collectible = this.props.allCollectibles[id];
                  return (
                    collectible && (
                      <LazyRender key={'collectible-' + collectible.id} Placeholder={CollectibleCardPlaceholder}>
                        <CollectibleCard collectible={collectible} />
                      </LazyRender>
                    )
                  );
                })
              : new Array(20).fill(0).map((i) => (
                  <div key={'card' + i} className="w-100 h-100 placeholder-card" style={{ marginBottom: '30px' }}>
                    <div style={{ position: 'relative' }}>
                      <div className="height-decider" style={{ display: 'block', paddingBottom: '100%' }} />
                      <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                    </div>
                  </div>
                ))}
          </DiscoverCardGroup>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...CollectibleActions,
  ...SpinnerActions,
  ...AccountActions,
};

const mapStateToProps = (state) => ({
  ...state.cache,
});

const component = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Carousel);
export default withRouter(component);
