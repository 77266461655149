import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionActions } from '@Actions/CollectionActions';
import Placeholder from '@Images/collection-placeholder.svg';
import { connect } from 'react-redux';
import DiscoverCarousel from './DiscoverCarousel';
import { AnimatedPlaceholderRect } from '@Components/placeholder/PlaceholderComponents';
import { buildCollectionLink, generateGradientBackground, getCollectionChainInfo } from '@Utils';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import { useHistory } from 'react-router';
import OiInfoTooltip from '@Components/shared/OiInfoTooltip';
import ChainIcon from '@Components/shared/ChainIcon';
import { Link } from 'react-router-dom';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 10000, min: 1400 },
    items: 3,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 1400, min: 800 },
    items: 2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

const FeaturedCollections = ({ getCollections, collections, featuredCollectionIds }) => {
  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    if (featuredCollectionIds) return;
    getCollections(`$top=12`, 'featured', 'All Content');
  }, []);

  const getCollectionCard = (collection) => {
    const { icon, variant, name: chainName } = getCollectionChainInfo(collection);
    let collectionLink = buildCollectionLink(collection, {});
    let collectionLinkObject = {
      pathname: collectionLink,
      state: {
        fromOix: true,
        unrestrictContent: collection.unrestrictContent,
      },
    };
    const { id, account, coverImageUrl, profileImageUrl, horizontalFeatureImageUrl, name } = collection;
    return (
      <Link to={collectionLinkObject} key={'collection' + id} className="featuredCollectionsCard">
        <div className="featuredCollectionsCard__cover-image-container" style={generateGradientBackground(id)}>
          {(horizontalFeatureImageUrl || coverImageUrl) && (
            <img width={'100%'} height={'100%'} className="featuredCollectionsCard__cover-image" src={horizontalFeatureImageUrl || coverImageUrl} />
          )}
        </div>
        <div className="featuredCollectionsCard__footer">
          <ProfileAvatar
            imageLink={profileImageUrl ?? Placeholder}
            width={45}
            height={45}
          />
          <div className="featuredCollectionsCard__info">
            <div className='featuredCollectionsCard__info--name'>{name}</div>
            <div className='featuredCollectionsCard__info--slug'>
              {account?.slug && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/${account?.slug}`)
                  }}>
                  {account?.profile?.name || account?.slug}
                </div>
              )}
            </div>
          </div>
        </div>
        <OiInfoTooltip title={chainName} placement="top">
          <div className={`featuredCollectionsCard__chain-icon featuredCollectionsCard__chain-icon--${variant}`}>
            <ChainIcon icon={icon} />
          </div>
        </OiInfoTooltip>
      </Link>
    );
  };

  return (
    <div className="featuredCollections">
      <div className="featuredCollections__header">{t('public.featuredCollections')}</div>
      <DiscoverCarousel responsive={responsive}>
        {featuredCollectionIds?.length
          ? featuredCollectionIds?.map((fcId) => {
              const collection = collections[fcId];
              return getCollectionCard(collection);
            })
          : [...Array(12).keys()].map((i) => {
              return (
                <div key={'featuredCollection' + i} className="w-100 mx-2 placeholder-card">
                  <div style={{ position: 'relative' }}>
                    <div style={{ display: 'block', paddingBottom: '100%' }} />
                    <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                  </div>
                </div>
              );
            })}
      </DiscoverCarousel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  collections: state.cache?.allCollections,
  featuredCollectionIds: state.discover?.collections?.featured?.['All Content'],
});

const actions = {
  getCollections: CollectionActions.getCollections,
};

export default connect(mapStateToProps, actions)(FeaturedCollections);
