import * as React from 'react';
import { Helmet } from 'react-helmet';
import bind from 'bind-decorator';
import { withRouter } from 'react-router';
import { Link, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '@Actions/UserActions';
import Icon from '@Components/shared/Icon';
import AppVariables from '@AppVariables';
import Collectible from '@Components/collectible/Collectible';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import Search from '@Components/shared/Search';
import { ModalActions } from '../actions/ModalActions';
import Input from '@Components/form/Input';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { AccountActions } from '@Actions/AccountActions';
import AppRoute from '@Components/shared/AppRoute';
import { createSelector } from 'reselect';
import Carousel from '@Components/shared/Carousel';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import AccountCard from '@Components/shared/AccountCard';
import { AnimatedPlaceholderRect } from '@Components/placeholder/PlaceholderComponents';
import { InlineLoader } from '@Components/shared/Spinner';
import CollectibleCard from '@Components/shared/CollectibleCard';
import { HashtagActions } from '@Actions/HashtagActions';
import AppScroller from '@Components/shared/AppScroller';
import { withTranslation } from 'react-i18next';

class Creators extends React.Component {
  LIMIT = 32;

  constructor(props) {
    super(props);
    this.state = {
      responsiveButton: false,
      shouldLoadMore: true,
      popular: true,
      trending: true,
      recent: true,
    };
  }

  entries = [
    {
      id: 1,
      groupName: this.props.t('public.trendingNow'),
      description: this.props.t('public.trendingNowCreatorDesc'),
      query: `$expand=Profile&$top=${this.LIMIT}`,
      group: 'trending'
    },
    {
      id: 2,
      groupName: this.props.t('public.recentJoined'),
      description: this.props.t('public.recentJoinedDesc'),
      query: `$expand=Profile&$top=${this.LIMIT}`,
      group: 'recent'
    },
    {
      id: 3,
      groupName: this.props.t('public.mostPopular'),
      description: this.props.t('public.mostPopulerCreatorDesc'),
      query: `$expand=Profile&$top=${this.LIMIT}`,
      group: 'popular'
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.group !== this.props.match.params.group ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.setState({ shouldLoadMore: true }, () => {
        this.createRequest();
      });
    }
  }

  componentDidMount() {
    this.props.getAccountCategories().then(() => {
      this.createRequest();
    });
  }

  updateCategory(category, to = null) {
    let query = new URLSearchParams(this.props.location.search);
    query.set('category', category.name);

    if (to) {
      this.props.history.push(`/discover/creators/${to}?${query.toString()}`);
    } else {
      this.props.history.replace('?' + query.toString());
    }
  }

  createRequest(loadMore = false) {
    let params = new URLSearchParams(this.props.location.search);
    let selectedCategory = this.props.selectedCreatorCategories?.find((x) => x.name === params.get('category'));
    let selectedCategoryName = selectedCategory ? selectedCategory.name : 'All Creators';

    const group = this.props.match.params.group || '';

    this.entries
      .filter((e) => (group ? e.group === group : true))
      .forEach((entry) => {
        let { query, group } = entry;
        const length = this.props.creators[group][selectedCategoryName]?.length || 0;
        if (!length || loadMore) {
          if (selectedCategory && selectedCategory.id !== 0) {
            query += `&$filter=category/id eq ${selectedCategory.id}`;
          }
          query += `&$skip=${length}`;
          this.props.getAccounts(query, group, selectedCategoryName).then((res) => {
            this.setState({
              [group]: res.payload.value.length >= this.LIMIT,
            });
          });
        }
      });
  }

  updateHistory(id) {
    const entry = this.entries.find((x) => x.id == id);

    if (entry) {
      this.props.history.push(`/discover/creators/${entry.group}`);
    }
  }

  loadMore() {
    this.createRequest(true);
  }

  @bind
  responsiveButtonClick() {
    this.setState((prevState) => ({
      responsiveButton: !prevState.responsiveButton,
    }));
  }

  render() {
    const { t } = this.props;
    let params = new URLSearchParams(this.props.location.search);
    const group = this.props.match.params.group || '';
    let selectedCategory = this.props.selectedCreatorCategories?.find((x) => x.name === params.get('category'));
    let selectedCategoryName = selectedCategory ? selectedCategory.name : 'All Creators';

    return (
      <div className="tab-container">
        <Helmet>
          <title> {t('public.creators')} </title>
        </Helmet>
        <div className="header-text ml-3">
          <h1 className="my-1" data-test-id="creators-top-header">{t('public.creators')}</h1>
          <div className="text-grey"> {t('public.discoverCreatorText')} </div>
        </div>
        <div className="top-bar mt-4" style={!group ? { display: 'none' } : null}>
          <div className="responsive-menu-button" onClick={this.responsiveButtonClick}>
            <Icon className="ml-1" type="fontawesome" src="fas fa-sliders-h" />
          </div>
          {group ? (
            <div className="selectable-item">
              <div className={this.state.responsiveButton ? 'select-input active-select-input' : 'select-input'}>
                <div className="home-button" onClick={() => this.props.history.goBack()}>
                  <Icon className="mr-1" type="fontawesome" src="fas fa-arrow-left" />
                  {t('public.back')}
                </div>
                <div className="input-select">
                  <Input.Select
                    className="mt-1 w-100"
                    name="creators"
                    items={this.props.selectedCreatorCategories}
                    displayExpr={"name"}
                    customOptionLabel={(item) => t(`public.${item.name}`)}
                    valueExpr={'id'}
                    value={this.props.selectedCreatorCategories.find(
                      (category) => category.id === (selectedCategory ? selectedCategory.id : 0),
                    )}
                    onChange={(category) => this.updateCategory(category)}
                    type="dark"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <>
          {this.entries
            .filter((e) => (group ? e.group === group : true))
            .map((entry) => (
              <AppScroller
                key={entry.id}
                dataLength={this.props.creators[entry.group][selectedCategoryName]?.length || 0}
                next={() => this.loadMore()}
                hasMore={group ? this.state[entry.group] : false}
                loader={<InlineLoader height={30} />}>
                <DiscoverCardGroup
                  hideCarousel={group}
                  hideInfo={!!group}
                  className="mt-3"
                  title={entry.groupName}
                  subtitle={entry.description}
                  seeAll={() => this.updateHistory(entry.id)}
                  key={'discover-group-' + entry.id}>
                  {this.props.creators[entry.group][selectedCategoryName]?.length
                    ? this.props.creators[entry.group][selectedCategoryName].map((creatorId) => {
                        const creator = this.props.allAccounts[creatorId];
                        return creator && <AccountCard key={`creators-${creatorId}`} accountData={creator} />;
                      })
                    : new Array(this.LIMIT).fill(0).map((i) => (
                        <div className="w-100 placeholder-card" key={'account' + i}>
                          <div style={{ position: 'relative' }}>
                            <div style={{ display: 'block', paddingBottom: '100%' }} />
                            <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                          </div>
                        </div>
                      ))}
                </DiscoverCardGroup>
              </AppScroller>
            ))}
        </>
      </div>
    );
  }
}
const getCreatorCategories = (state) => state.service.AccountCategories;

export const selectCreatorCategories = createSelector(getCreatorCategories, (data) => {
  let categoryArr = [{ id: 0, name: 'All Creators' }];

  if (data) {
    categoryArr = [{ id: 0, name: 'All Creators' }, ...data];
  }
  return categoryArr;
});

const mapDispatchToProps = {
  ...AccountActions,
  ...SpinnerActions,
  ...CollectibleActions,
  ...ServiceActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.service,
  ...state.user.account,
  ...state.discover,
  ...state.cache,
  selectedCreatorCategories: selectCreatorCategories(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation()(Creators)
);
