import Icon from '@Components/shared/Icon';
import * as React from 'react';
import { useRef } from 'react';
import Carousel from 'react-multi-carousel';

const defaultResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 10000, min: 1400 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 1400, min: 800 },
    items: 2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

const CarouselButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  // it doesn't work right now
  // if (currentSlide === slidesToShow) return <></>;
  return (
    <>
      <div className="left-button carousel-button" onClick={() => previous()}>
        <Icon src="fas fa-chevron-circle-left" type="fontawesome" />
      </div>
      <div className="right-button carousel-button" onClick={() => next()}>
        <Icon src="fas fa-chevron-circle-right" type="fontawesome" />
      </div>
    </>
  );
};

const DiscoverCarousel = ({ children, responsive, autoPlay }) => {

  return (
    <div className="discover-carousel">
      <Carousel
        showDots
        infinite
        autoPlay={autoPlay}
        autoPlaySpeed={5000}
        containerClass="discover-carousel"
        dotListClass="custom-dot-list"
        minimumTouchDrag={20}
        swipeable={true}
        customButtonGroup={<CarouselButtonGroup />}
        draggable={true}
        responsive={responsive || defaultResponsive}>
        {children}
      </Carousel>
    </div>
  );
};

export default DiscoverCarousel;
