import * as React from 'react';
import { Helmet } from 'react-helmet';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Icon from '@Components/shared/Icon';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import { AccountActions } from '@Actions/AccountActions';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import { AnimatedPlaceholderRect } from '@Components/placeholder/PlaceholderComponents';
import { InlineLoader } from '@Components/shared/Spinner';
import { HashtagActions } from '@Actions/HashtagActions';
import HashtagCard from '@Components/shared/HashtagCard';
import { Redirect } from 'react-router';
import AppScroller from '@Components/shared/AppScroller';
import { withTranslation } from 'react-i18next';

class Hashtags extends React.Component {
  LIMIT = 32;

  constructor(props) {
    super(props);
    this.state = {
      responsiveButton: false,
      shouldLoadMore: true,
      popular: true,
      trending: true,
      recent: true,
    };
  }

  entries = [
    // TODO: Update the queries below
    {
      id: 1,
      groupName: this.props.t('public.trendingNow'),
      description: this.props.t('public.trendingNowDesc'),
      query: `$top=${this.LIMIT}`,
      group: 'trending'
    },
    {
      id: 2,
      groupName: this.props.t('public.recentUsed'),
      description: this.props.t('public.recentUsedDesc'),
      query: `$top=${this.LIMIT}`,
      group: 'recent'
    },
    {
      id: 3,
      groupName: this.props.t('public.mostPopular'),
      description: this.props.t('public.mostPopularDesc'),
      query: `$top=${this.LIMIT}`,
      group: 'popular'
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.group !== this.props.match.params.group) {
      this.setState({ shouldLoadMore: true }, () => {
        this.createRequest();
      });
    }
  }

  componentDidMount() {
    this.createRequest();
  }

  createRequest(loadMore = false) {
    let params = new URLSearchParams(this.props.location.search);
    const group = this.props.match.params.group || '';
    const { hashtags } = this.props;
    this.entries
      .filter((e) => (group ? e.group === group : true))
      .forEach((entry) => {
        let { query, group } = entry;
        const length = hashtags[group].length;
        if (!length || loadMore) {
          query += `&$skip=${length}`;
          this.props.getHashtags(query, group).then((res) => {
            this.setState({
              [group]: res.payload.value.length >= this.LIMIT,
            });
          });
        }
      });
  }

  updateHistory(id) {
    const entry = this.entries.find((entry) => entry.id === id);

    if (entry) {
      this.props.history.push(`/discover/hashtags/${entry.group}`);
    }
  }

  loadMore() {
    this.createRequest(true);
  }

  @bind
  responsiveButtonClick() {
    this.setState((prevState) => ({
      responsiveButton: !prevState.responsiveButton,
    }));
  }

  render() {
    const { t } = this.props;
    let params = new URLSearchParams(this.props.location.search);
    const group = this.props.match.params.group || '';
    if (group && !this.entries.some((e) => e.group === group)) {
      return <Redirect to="/discover/hashtags" />;
    }

    return (
      <div className="tab-container">
        <Helmet>
          <title> {t('public.hastags')} </title>
        </Helmet>
        <div className="header-text ml-3">
          <h1 className="my-1" data-test-id="hashtags-top-header"> {t('public.hastags')} </h1>
          <div className="text-grey"> {t('public.hastagsSubtext')} </div>
        </div>

        <div className="top-bar mt-4" style={group ? null : { display: 'none' }}>
          <div
            className="responsive-menu-button"
            style={!this.props.history.location.search ? { display: 'none' } : null}
            onClick={this.responsiveButtonClick}>
            <Icon className="ml-1" src="fas fa-sliders-h" type="fontawesome" />
          </div>
          {true ? (
            <div className="selectable-item">
              <div className={this.state.responsiveButton ? 'select-input active-select-input' : 'select-input'}>
                <div
                  className="home-button"
                  onClick={() => {
                    this.props.history.push('/discover/hashtags');
                  }}>
                  <Icon className="mr-1" type="fontawesome" src="fas fa-arrow-left" />
                  {t('public.back')}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {this.entries
          .filter((e) => (group ? e.group === group : true))
          .map((entry) => (
            <AppScroller
              key={entry.id}
              dataLength={this.props.hashtags[entry.group].length || 0}
              next={() => this.loadMore()}
              hasMore={group ? this.state[entry.group] : false}
              loader={<InlineLoader height={30} />}>
              <DiscoverCardGroup
                key={'discover-group-' + entry.groupName}
                className="mt-3"
                hideCarousel={group}
                hideInfo={!!group}
                seeAll={() => this.updateHistory(entry.id)}
                subtitle={entry.description}
                title={entry.groupName}>
                {this.props.hashtags[entry.group].length
                  ? this.props.hashtags[entry.group].map((tag) => {
                      const hashtag = this.props.allHashtags[tag];
                      return (
                        <HashtagCard
                          key={'hashtag' + hashtag.tag + entry.group}
                          followHashtag={this.props.followHashtag}
                          getHashtag={this.props.getHashtag}
                          hashtag={hashtag}
                          history={this.props.history}
                          unfollowHashtag={this.props.unfollowHashtag}
                        />
                      );
                    })
                  : new Array(this.LIMIT).fill(0).map((i) => (
                      <div key={'account' + i} className="w-100 placeholder-card">
                        <div style={{ position: 'relative' }}>
                          <div style={{ display: 'block', paddingBottom: '100%' }} />
                          <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                        </div>
                      </div>
                    ))}
              </DiscoverCardGroup>
            </AppScroller>
          ))}
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.service,
  ...state.user.account,
  ...state.discover,
  ...state.cache,
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation()(Hashtags));
