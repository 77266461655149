import React from 'react';
import Main1 from '@Images/discover/main-1.png';
import Main2 from '@Images/discover/main-2.png';
import Main3 from '@Images/discover/main-3.png';
import DiscoverCarousel from './DiscoverCarousel';
import { useTranslation } from 'react-i18next';
import Button from '@Components/form/Button';
import Icon from '@Components/shared/Icon';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const responsive = {
  tablet: {
    breakpoint: { max: 8000, min: 0 },
    items: 1,
  },
};

const mainSliderContent = [
  {
    imageDesktop: (
      <img
        className="mainSlider__image mainSlider__image--desktop"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/desktop_zero_percent.jpg 1x, https://cdn.oix.app/site/media/sliders/desktop_zero_percent@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/desktop_zero_percent@3x.jpg 3x"
      />
    ),
    imageMobile: (
      <img
        className="mainSlider__image mainSlider__image--mobile"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/mobile_zero_percent.jpg 1x, https://cdn.oix.app/site/media/sliders/mobile_zero_percent@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/mobile_zero_percent@3x.jpg 3x"
      />
    ),
    title: 'public.mainSlider.zero_percent.title',
    subTitle: 'public.mainSlider.zero_percent.subTitle',
    primaryButton: {
      text: 'public.mainSlider.zero_percent.primaryText',
      link: '/discover/creations',
    },
    secondaryButton: {
      text: 'public.mainSlider.zero_percent.secondaryText',
      link: '/collectible/upload',
    },
    hasLearnMore : true
  },
  {
    imageDesktop: (
      <img
        className="mainSlider__image mainSlider__image--desktop"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/desktop_001.jpg 1x, https://cdn.oix.app/site/media/sliders/desktop_001@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/desktop_001@3x.jpg 3x"
      />
    ),
    imageMobile: (
      <img
        className="mainSlider__image mainSlider__image--mobile"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/mobile_001.jpg 1x, https://cdn.oix.app/site/media/sliders/mobile_001@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/mobile_001@3x.jpg 3x"
      />
    ),
    title: 'public.mainSlider.welcome.title',
    subTitle: 'public.mainSlider.welcome.subTitle',
    primaryButton: {
      text: 'public.mainSlider.welcome.primaryText',
      link: '/discover/creations',
    },
    secondaryButton: {
      text: 'public.mainSlider.welcome.secondaryText',
      link: '/collectible/upload',
    },
    hasLearnMore : true
  },
  {
    imageDesktop: (
      <img
        className="mainSlider__image mainSlider__image--desktop"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/desktop_002.jpg 1x, https://cdn.oix.app/site/media/sliders/desktop_002@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/desktop_002@3x.jpg 3x"
      />
    ),
    imageMobile: (
      <img
        className="mainSlider__image mainSlider__image--mobile"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/mobile_002.jpg 1x, https://cdn.oix.app/site/media/sliders/mobile_002@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/mobile_002@3x.jpg 3x"
      />
    ),
    title: 'public.mainSlider.explore.title',
    subTitle: 'public.mainSlider.explore.subTitle',
    primaryButton: {
      text: 'public.mainSlider.explore.primaryText',
      link: '/discover/creations',
    },
    secondaryButton: {
      text: 'public.mainSlider.explore.secondaryText',
      link: '/collectible/upload',
    },
    hasLearnMore : true
  },
  {
    imageDesktop: (
      <img
        className="mainSlider__image mainSlider__image--desktop"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/desktop_003.jpg 1x, https://cdn.oix.app/site/media/sliders/desktop_003@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/desktop_003@3x.jpg 3x"
      />
    ),
    imageMobile: (
      <img
        className="mainSlider__image mainSlider__image--mobile"
        src="image-src.png"
        srcSet="https://cdn.oix.app/site/media/sliders/mobile_003.jpg 1x, https://cdn.oix.app/site/media/sliders/mobile_003@2x.jpg 2x,
        https://cdn.oix.app/site/media/sliders/mobile_003@3x.jpg 3x"
      />
    ),
    title: 'public.mainSlider.meet.title',
    subTitle: 'public.mainSlider.meet.subTitle',
    primaryButton: {
      text: 'public.mainSlider.meet.primaryText',
      link: '/discover/creations',
    },
    secondaryButton: {
      text: 'public.mainSlider.meet.secondaryText',
      link: '/collectible/upload',
    },
    hasLearnMore : true
  },
];

const MainSlider = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const scrollIntoVideo = () => {
    document.getElementById('meetOix').scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const getCard = ({ 
    imageDesktop, 
    imageMobile, 
    title, 
    subTitle, 
    primaryButton, 
    secondaryButton,
    hasLearnMore
  }) => {
    return (
      <div className="mainSlider__card" key={title}>
        {imageDesktop}
        {imageMobile}
        <div className="mainSlider__title">{t(title)}</div>
        <div className="mainSlider__subTitle">{t(subTitle)}</div>
        <div className="mainSlider__buttons">
          {primaryButton &&
            <Link to={{ pathname: primaryButton.link }} target={primaryButton.link.startsWith('http') ? "_blank" : null}>
              <Button type="primary" text={t(primaryButton.text)} />
            </Link>
          }

          {secondaryButton &&
            <Link to={{ pathname: secondaryButton.link, state: { background: location } }} target={secondaryButton.link.startsWith('http') ? "_blank" : null}>
              <Button type="white" text={t(secondaryButton.text)} />
            </Link>
          }
        </div>
          <div className="mainSlider__learnMore" onClick={scrollIntoVideo}>
            {hasLearnMore &&
              <>
                <Icon className="mainSlider__play" src="fas fa-play-circle" type="fontawesome" />
                <span className="mainSlider__learnMoreText">{t('public.mainSlider.learnMore')}</span>
              </>
            }
            &nbsp; {/* This is so that we have some content, otherwise it shifts the layout of these cards*/}
          </div>
      </div>
    );
  };
  return (
    <div className="mainSlider">
      <DiscoverCarousel responsive={responsive} autoPlay>
        {mainSliderContent.map((content) => getCard(content))}
      </DiscoverCarousel>
    </div>
  );
};

export default MainSlider;
