import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CollectionIcon } from '@Images/icons/collection.svg?component';
import Icon from '@Components/shared/Icon';
import DiscoverCarousel from './DiscoverCarousel';
import { Link } from 'react-router-dom';
import { buildHelpCenterLink } from '@Utils';

const howToGetStartedContent = [
  {
    icon: <Icon src="fas fa-wallet" type="fontawesome" />,
    title: 'public.howToStart.setupTitle',
    description: 'public.howToStart.setupDescription',
    articleId: '4553463596955'
  },
  {
    icon: <CollectionIcon />,
    title: 'public.howToStart.createTitle',
    description: 'public.howToStart.createDescription',
    articleId: '6698440964635'
  },
  {
    icon: <Icon src="fas fa-plus-circle" type="fontawesome" />,
    title: 'public.howToStart.addTitle',
    description: 'public.howToStart.addDescription',
    articleId: '4727518703387'
  },
  {
    icon: <Icon src="fas fa-bars" type="fontawesome" />,
    title: 'public.howToStart.listTitle',
    description: 'public.howToStart.listDescription',
    articleId: '4985945096859'
  },
];

const HowToStart = () => {
  const { t } = useTranslation();

  const getCard = ({ icon, title, description, articleId }) => {
    return (
      <div className="howToStart__card" key={title}>
        <div className="howToStart__icon">{icon}</div>
        <div className="howToStart__title">{t(title)}</div>
        <div className='howToStart__descriptionArea'>
          <div className="howToStart__description">{t(description)}</div>
          <Link to={{ pathname: buildHelpCenterLink('articles', articleId) }} target="_blank" className="howToStart__link">
            {t('public.howToStart.learnMore')}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="howToStart">
      <div className="howToStart__header">{t('public.howToStart.title')}</div>
      <DiscoverCarousel>{howToGetStartedContent.map((content) => getCard(content))}</DiscoverCarousel>
    </div>
  );
};

export default HowToStart;
